@import url("./fonts.css");
@import url("./comman.css");
 
/* Theme Color Css */
:root {
    --themeFont: "Circular Std";
}

/* Theme Color Css */
html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-size: 16px;
    line-height: 1.571429;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    background-color: #fff;
    font-family: var(--themeFont);
    color: #333;
    font-weight: 400;
}

.body-hidden {
    overflow: hidden;
}
.bg-grey{
    background-color: #f5f8fa;
}
.b-b{
    border-bottom: solid 1px var(--themeColor);
    padding-bottom: 10px;
}
.w-15{
    width: 15%;
}
.w-20{
    width: 20%;
}
.w-25{
    width: 25%;
}
.w-30{
    width: 30%;
}
/* Skip  */
.skiptomaincontent,
.skiptomaincontent:focus {
    left: 0;
    position: absolute;
    transition: background 0.3s ease;
}

.skiptomaincontent {
    background: transparent;
    color: #5a6872;
    padding: 10px !important;
    top: -3.6rem;
    z-index: -1;
    font-size: 16px;
    height: 0;
    color: #686868;
    font-weight: 600;
    line-height: 20px;
}

.skiptomaincontent:focus {
    background: #fff;
    top: 0;
    z-index: 500;
    outline: 0.2rem solid #5c2c14 !important;
    height: 2.5rem;
    color: #686868;
}
/* Skip  */

/* Comman Css Start */
.custom-check{
    width: fit-content;
}
.custom-check .form-check-input{
    opacity: 0;
    display: none;
}
.custom-check .checkBoxText{
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    width: 100% ;
}
    @media (max-width: 1600px){ 
        .custom-check .checkBoxText{
            font-size: 14px;
            line-height: 18px;
        }
    }
.checkBoxText svg{
    margin-left: 0.5rem;
    width: 1.5rem;
}
.custom-check .form-check-label .checkBoxText.themecolor_text {
    color: var(--themeColor);
    font-size: 16px;
    font-weight: 600;
}
.custom-check .form-check-label{
    position: relative;
    cursor: pointer;   
    display: flex; 
    align-items: start;
    color: #151E1F;
    cursor: pointer;
    font-size: 16px;
    line-height: 21px; 
    font-weight: 300; 
}
.custom-check .form-check-label .checkBox { 
    position: relative;
    min-width: 22px;
    min-height: 22px;
    border: solid 2px #979CA1;
    border-radius: 5px; 
}
.custom-check input:checked ~ .form-check-label .checkBox {
    box-shadow: var(--inputShadowFocus);
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.custom-check .form-check-label .checkBox:after {
    content: "";
    position: absolute;
    display: none;
}
.custom-check .form-check-label .checkBox:after {
    left: 7px;
    top: 2px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.custom-check input:checked ~ .form-check-label .checkBox:after {
    display: block;
}
.custom-check input:checked ~ .form-check-label .checkBox{
    background-color: var(--themeColor);
    border:var(--themeColor);
}
.custom-check .form-check-label .checkBox:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: var(--inputShadowFocus);
}
 
.custom-check input:disabled ~ .form-check-label .checkBox{
    background-color: #f1f1f1;
    box-shadow: inherit;
    cursor: not-allowed;
}
.checkDisableCheckbox.custom-check input:disabled ~ .form-check-label .checkBox{
    border: solid 2px #979CA1;
}

.checkDisableCheckbox.custom-check .form-check-label .checkBox:after {
    left: 5px;
    top: 0px;
    border-color: #979CA1;
}
.form-radio {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 0.5rem;    
}
.form-radio .form-check-input {
    position: relative;
    min-width: 20px;
    min-height: 20px; 
    margin-top: 0px;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: solid 2px #979CA1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    margin: 0px;
    cursor: pointer;
}
.form-radio .form-check-input[type=radio] {
    border-radius: 50%;
}
.form-radio  .form-check-input:checked {
    background-color: #fff;
    border: solid 5px var(--themeColor);
}
.form-radio  .form-check-label{
    padding-left: 15px;
    color: #151E1F;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    cursor: pointer;
    margin: 0;
}

.disabled-input
{    
    pointer-events: none;
    cursor: default;
    background: #ededed;
}
/* Comman Css End */

/* Top Header Section Start */
.current { 
    background-color: var(--HeaderBgColor);
    text-align: center;
    position: relative;
    z-index: 1;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.current-inner {
    display: flex;
    align-items: center;
}

.current-inner .current-heading {
    font-size: 14px;
    color: #fff;
    text-align: left;
    line-height: 18px;
    font-weight: 100;
}

.current-inner .current-heading div {
    font-weight: 600;
    font-size: 16px;
}

.current-inner .btn,
.top-notifaction  .btn {
    padding: 8px 15px;
    border: 0px;
    border-radius: 6px;
    margin-left: 15px;
}

.current-inner .btn:focus {
    outline: 1px solid black;
}

.covid-Section { 
    background-color: #fff; 
}

.current,
.covid-Section,
.top-notifaction {
    padding: 8px 30px;
    position: relative;
}

.covid-Section .close-icon,
.top-notifaction .close-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color: #000;
    font-family: var(--themeFont);
    text-decoration: inherit;
    border: 0;
    background: transparent;
}

.covid-Section .small-text,
.top-notifaction .info {
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0px;
    color: #000;
    padding-right: 10px;
}
.top-notifaction .info{ 
    margin:  0px; 
} 
@media (max-width: 998px) {
    .current,
    .covid-Section,
    .top-notifaction {
        padding: 8px 15px;
    }
    
}
/* Top Header Section End */
/* Loader Css Start*/ 

.pre-loader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pre-loader .icon-full svg {
    width: 16rem;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

.small-icon-full svg {
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
    margin-right: 0.75rem;
}

.pre-loader .icon-full {
    margin-bottom: 5rem;
    color: var(--themeColor);
}
.icon-full svg {
    width: 100%;
    height: auto;
} 
@-webkit-keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loader-large-text {
    color: var(--themeColor);
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 48px;
    line-height: 58px;
    font-weight: 700;
}

.loader-small-text {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}

.content-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    z-index: 10;
}
@media (max-width: 767px) {
    .pre-loader .icon-full {
        margin-bottom:3rem; 
    }
    .loader-large-text { 
        margin-bottom: 0.5rem;
        font-size: 28px;
        line-height: 30px; 
    } 
    .loader-small-text { 
        font-size: 18px; 
    }

}

.inner-content-loader {
    background-color: var(--themeColor);
    color: #fff;
    padding: 0.75rem 2.5rem; 
    margin:0 auto;  
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.small-icon-full {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.small-icon-full span {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
}

/* Loader Css end */ 
/* Information Message Start */ 
.infomsg .infoIcon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.infoDescrption {
    width: 79%;
    font-weight: 400;
    border-radius: 0.5rem;
} 

.animate-tooltip {
    width: 100%;
    display: flex;
  }
  
  .show-site-div .animate-tooltip {
    width: auto;
  }
  
  .tooltip-icon {
    background-color: #F2BA16;
    height: 4rem;
    width: 4rem;
    margin-right: 1.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
  }

  .tooltip-icon svg{
    fill: #fff;
  }
  
  .tooltip-content {
    padding: 0.75rem;
    margin-top: 0;
    font-size: 16px;
    line-height: 21px;
    border-radius: 0.5rem;
    background-color: #FAF5EB;
  }
  .tooltip-content {
    font-weight: 400;
    width: 100%;
  }

  @media only screen and (max-width: 1024px) { 
    .searchALllData .show-site-div .animate-tooltip{
        flex-direction: column !important;
    }

    .searchALllData .show-site-div .animate-tooltip .tooltip-icon{
        margin-bottom: 1rem;
        margin-right: 0rem;
    }

    .searchALllData .show-site-div .animate-tooltip .infoDescrption{
        font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 0.75rem !important;
    font-weight: 400;
    width: 90%;
    }

    .searchMain .infomsg.mt-24{
        margin-top: 3rem !important;
    }
  }
      
/* Information Message End */
 /* Date Css start */
 .date-text{
    font-weight: 300;
    color: var(--placeHolderColor);
} 
.date-text.font-bold{
    font-weight: 700;
    color: var(--themeColor);
} 

 /* Date Css End */
/* Model Custom Css */
.modal-open,
.modal{
    padding: 0px !important;
}
.modal-content{
    border-radius: 10px;
    border: 0;
}
.close-icon {
    padding: 5px;
    width: 25px;
    height: 25px;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
}
.close-icon  svg{
    fill: #000;
    width: 100%;
    height: 100%;
}
.close-icon:hover  svg{
    fill: var(--themeColor);
}
/* Main Form Control Theme Css Start  */
.form-group {
    margin-bottom: 1.2rem;
}
.form-group label{
    display: inline-block;
    width: 100%; 
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    color: var(--themeLabelColor);
    line-height: normal;
}

.form-group .form-control{
    display: block;
    width: 100%;
    padding: 7px 14px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5px;
    font-weight: 200;
    background-clip: padding-box;
    border: 1px solid #999;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group .form-control:focus{ 
        color: #495057;
        background-color: #fff;
        border-color: var(--themeFormBorder);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(var(--themeColorRgba), 25%);
} 
.form-group select.form-control{
    cursor: pointer;    
}
.form-group  select.form-control:not([size]):not([multiple]){
    height: calc(2.25rem + 4px);
}
@media (max-width: 767px) {
    .form-group {
        margin-bottom: 1rem;
    }
    .form-group label { 
        margin-bottom: 5px;
        font-size: 14px;  
    }
    .form-group .form-control { 
        font-size: 15px;
        line-height: 1.5; 
    }
}
/* Main Form Control Theme Css Start  */
/* Only Background Image */
.dropdown-footer label span div { 
   background-image: url('../icons/gray-square.svg');  
  }
  .dropdown-footer label span div div {
    background-image: url('../icons/primary-checkbox.svg');  
  }
  .icon-alert {
     background-image: url("../icons/alert.svg");  
  }
  .icon-alert:hover,
  .icon-alert:focus {
      background-image: url("../icons/alert-hover.svg"); 
  }   
  .icon-alert {
    background-image: url("../icons/alert.svg");  
  }
.banner-section::after { 
    background: url("../images/Trees-BANNER.png");   
  }
  
/* Only Background Image */
/* Custom Dropdown */

.customDropdown .btn-secondary span {
    font-weight: 400;
  }
  .customDropdown .dropdown-toggle{
    background: transparent;
    cursor: pointer;
    border: 0;
    display: flex;
    align-items: center;
  }
  .customDropdown .dropdown-toggle span{
    font-weight: 600;
  }
  .customDropdown .dropdown-toggle .downIcon{
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 10px;
    fill: #000;
  } 
  .customDropdown .dropdown-toggle.text-black .downIcon{
    fill: #000;
  }
  .customDropdown .dropdown-toggle.text-black,
  .customDropdown .dropdown-toggle.text-black:hover,
  .customDropdown .dropdown-toggle.text-black:focus{
    color: #000; 
  }
  .customDropdown .dropdown-toggle::after {
    display: none;
  } 
  .customDropdown .dropdown-toggle:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 8px 0 rgba(var(--themeDateBg), 0.8);
    color: #000;
  }
  .customDropdown .dropdown-menu {
    left: inherit;
    position: absolute;
    top: 100%;
    min-width: 110px;
    right: 0;
    z-index: 999; 
    float: left;
    margin: 0.125rem 0 0;
    overflow: hidden;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 25px 30px -10px rgb(37 17 63 / 11%);
    border: 0px;
    padding: 0px;
  }
  
  .customDropdown .dropdown-item {
    display: block;
    padding: 12px 15px;
    font-weight: 400;
    color: #3a3a3a;
    text-align: left;
    font-size: 15px;
    line-height: 14px;
    text-decoration: none;
    transition: transform 0.5s ease;
    border-bottom: solid 1px #f3f3f3;
  }
  
  .customDropdown .dropdown-item:hover {
    background:  rgba(var(--themeDateBg), 0.1);
    color: var(--themeColor);
  }
  
  .customDropdown .dropdown-item:last-child {
    border-bottom: 0px;
  }  
  
/* Custom Dropdown */
/* Error Message Start */

.no-data-img img,
.error-data-img img {
    width: 100%;
    height: 100%;
    max-width: 50rem;
}

.place-no-data,
.error-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.no-data-text,
.error-data-text {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}

.no-data-text h1,
.error-data-text h1 {
    font-size: 48px;
    line-height: 58px;
    font-weight: 700;
    color: var(--themeColor);
}

.error-data-para p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
}

.no-data-btn,
.error-data-btn {
    margin-top: 3rem;
}

@media screen and (min-width: 1061px) and (max-width: 1370px) { 
    .no-data-img img,
    .error-data-img img {
        max-width: 40rem;
    } 
}
@media only screen and (max-width: 991px){
    .no-data-text h1, .error-data-text h1 {
        font-size: 32px;
        line-height: 36px; 
        text-align: center;
    }
    .error-data-para p {
        font-size: 16px;
        line-height: 16px; 
        text-align: center;
    }
}
 /* Error Message End */
 .padder-5 {
    padding: 50px 0px; 
    min-height: calc(100vh - 395px);
}
@media only screen and (max-width: 991px){
    .padder-5 {
        padding: 30px 0px; 
    }
}
/* All Title  Start*/
.allHeading{
    margin-bottom:15px;
}
.main_title{
    font-size: 40px;
    color: var(--themeColor);
    margin-bottom:0px;
    font-weight: 600;
}

.main_bread{
    list-style: none;
    padding: 0;
    line-height: 18px;
    display: inline-block;
    width: 100%;
}
.main_bread li{
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
}

.page_title{
    margin: 25px 0px 10px;
    font-size: 28px; 
    font-weight: 600;
    color: var(--themeColor);
}
.b-t {
    border-top:solid 1px #ddd !important;
    margin: 0px -25px;
}
.peregraph{
    font-size: 16px;
    line-height: 21px;
}
@media only screen and (max-width: 991px){
    .main_title {
        font-size: 26px;
        line-height: 35px;
    }

    .page_title{
        font-size: 20px;
        margin: 15px 0px 5px;
    }
    .main_bread li {
        font-size: 14px;
        font-weight: 500;
    }
    .peregraph{
        font-size: 14px;
        line-height: 18px;
    }
}
/* All Title  End*/
/* Card  Start*/
.card-theme{
    border: 0;
    background: #fff; 
    -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 3%);
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 3%);
    border-radius: 15px; 
}
.card-theme .card-body{
    padding:  30px 25px;
} 

@media only screen and (max-width: 991px){
    .card-theme .card-body{
        padding: 15px;
    }
    .b-t { 
        margin: 10px -15px !important;
    }
} 
/* Card  Start End*/
/* Alert Model Start */ 
.alertModel .login_txt_pop {
    font-size: 33px;
    line-height: 38px;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 0px;
    color: var(--themeColor);
}
.alertModel  .modal-body{
    padding: 30px 20px;
}
.alertModel .loginError{ 
    color: #000; 
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 300;
} 
.alertModel .btn-outline{
    box-shadow: 0 12px 24px -4px rgb(46 91 115 / 30%), 0 6px 8px -3px rgb(0 0 0 / 5%);
}   
/* Alert Model End */
/* All Table Css Start */

.gridtable{
    max-height: 310px;
    overflow: auto;
    margin-top: 30px;
    border: solid 1px #ddd;
    min-height: 52px;
}
.gridtable table {
    background-color: #ffffff; 
    width: 100%; 
    overflow-wrap: break-word;
    margin: 0;
}
.gridtable thead {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%; 
}
.gridtable thead::after{
    content: '';
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    bottom: -1px;
    background-color: #dee2e6;
}
.gridtable thead th {
    font-size: 18px;
    font-weight: 600;
    color: #000; 
    border: 0;
}
 .gridtable thead th,
 .gridtable tbody td {
    padding: 10px;
}
 .gridtable tbody td { 
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    vertical-align: middle;
} 

@media only screen and (max-width: 767px) { 
    .gridtable thead th, .gridtable tbody td {
        white-space: nowrap;
        width: 100%;
    }
}
/* All Table Css Start */
/* Custom Button Right Side */
.custom-btn{
    display: flex; 
    justify-content: flex-end;
} 
.custom-btn .btn{
    width: 280px;
}
@media only screen and (max-width: 767px) {  
    .custom-btn .btn{
        width: 100%;
    } 
}   
/* Custom Button Right Side */

/* next page css start */
.next_head{
    display: flex;
    align-items: center;
    justify-content: center;
}
.next_head span{
    font-size: 18px;
    font-weight: 500;
    margin: 0px 5px;
}
.next_head .next_link ,
.next_head .previous_link{
    font-size: 18px;
    font-weight: 500;
}
@media only screen and (max-width: 767px) {
    .next_head span{
        font-size: 14px;
        line-height: 16px;
    }
    .next_head .next_link ,
    .next_head .previous_link{
    font-size: 14px;
    }
}
/* next page css end */
/* Auto Saerch Design Start */
.auto-search {
    position: relative;
}
.search_icon_input {
    position: absolute;
    width: 40px;
    height: 100%;
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    border-color: #999;
    top: 0;
    right: 0;
    padding: 0;
    background: transparent;
    outline: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0px;
}

.search_icon_input .searchIcon {
    height: 25px;
    width: 25px;
}
.search_icon_input .searchIcon{
    fill: var(--themeColor);
} 
.search_icon_input .closeIcon {
    height: 15px;
    width: 15px;
    fill: #000;
    margin: 0;
}
.search_icon_input .closeIcon:hover,
.search_icon_input .closeIcon:focus{
    fill: var(--themeColor);
}
.auto-search .form-control  {
    padding-right:50px;
}
.auto-search-list{
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: var(--inputShadow);
    border-radius:0rem 0rem 0.5rem; 
    z-index: 2;
}
.auto-search-list ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    max-height: 200px;
    overflow: auto;
}
.auto-search-list ul li{
    border-bottom: solid 1px #ddd; 
}
.auto-search-list ul li:last-child{
    border-bottom: 0px;
}
.auto-search-list ul li .listing{
    padding: 8px 10px;
    display: inline-block;
    width: 100%;
    font-size: 16px;
    color: #000;
}
.auto-search-list ul li .listing:hover,
.auto-search-list ul li .listing:focus{
    background: #f5f8fa;
}
/* Auto Saerch Design Start */

/* Attention modal css start */
.attention_modal .modal-content{
    border-radius: 10px;
    overflow: hidden;
}
.attention_modal .modal-header{
    padding: 0;
}
.attention_modal .modal-header .common{
    width: 100%;
}
.attention_modal .heading_text{
    width: 100%;
    color: #fff;
    background-color: var(--themeColor);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
    margin: 0;
}
.attention_modal .second_title{
    color: var(--themeColor);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    background-color: #fff;
    padding: 10px 0;
    margin-bottom: 0;
}
.attention_modal .alert_heading{
    font-size: 16px;
    font-weight: 600;
    color: var(--themeColor);
}
.attention_modal .detail_text{
    font-size: 16px;
    font-weight: 500;
    color: var(--themeColor);
}
.attention_modal .detail_text span{
    font-weight: 400;
    color: #000;
}
.attention_modal .center_detail .top .detail_text:last-child{
    margin: 0;
}
.attention_modal .modal-body{
    background: #f1f1f1;
    max-height: 360px;
    overflow: auto;
}
.attention_modal .center_detail{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
    margin-bottom: 15px;
}
.attention_modal .center_detail .top{
    padding: 15px;
    border-bottom: 1px solid #dfdfdf;
}
.attention_modal .center_detail .center{
    padding: 15px;
}
.attention_modal .modal-footer{
    justify-content: center;
}
.attention_modal .done_btn{
    padding: 10px 25px;
    font-size: 14px;
    line-height: 20px;
}
.attention_modal .modal-footer{
    padding: 10px 0;
    border: 0;
}
/* Attention modal css end */

/* Information modal css start */
.allmessageModal  .modal-header{
    border-bottom: 1px solid #e9ecef;
}
.modal.information .modal_heading,
.modal.allmessageModal .modal_heading{
    font-size: 28px;
    font-weight: 600;
    color: var(--themeLabelColor);
    margin-bottom: 0;
}
.modal.allmessageModal .btn,
.modal .btn.odd{
    padding: 10px 20px;
}
.allmessageModal  .modal-header,
.allmessageModal  .modal-body,
.allmessageModal  .modal-footer{
    padding: 15px 25px;
}
@media only screen and (max-width: 400px) {
    .modal.allmessageModal .btn,
    .modal .btn.odd {
        padding: 8px;
        font-size: 15px;
    } 
}
@media (min-width: 576px){ 
    .modal.allmessageModal .modal-dialog {
        max-width: 500px; 
    }
}
/* Information modal css end */
/* Empty Card Css Start*/
.emptyCard{
    display: flex;
    flex-flow: column;
    width: 100%;
    border: solid 1px #ddd;
    border-radius: 6px;
    padding: 15px; 
    align-items: center;
}
.emptyIcon{
    display: flex;
    width: 65px;
    height: 65px;
    background-color: rgba(var(--themeColorRgba), 10%);
    border-radius: 50%;
    margin-bottom: 10px;
    padding: 12px;
}
.emptyIcon svg{
    width: 100%;
    height: 100%;
    fill: var(--themeColor);
}
.emptyHeading{
    font-size: 20px;
    line-height: 22px;    
    color: #000;
    font-weight: 600;
    text-align: center;
}
.emptyHeading span{
    color: var(--themeColor);
    font-weight: 600;
}
@media only screen and (max-width: 560px) { 
    .emptyHeading{
        font-size: 16px;}
}
/* Empty Card Css End*/

/* Attention modal css start */
.register_modal .modal-content{
    border-radius: 10px;
    overflow: hidden;
}
.register_modal .modal-header{
    padding: 0;
}
.register_modal .modal-header .common{
    width: 100%;
}
.register_modal .heading_text{
    width: 100%;
    color: #fff;
    background-color: var(--themeColor);
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding: 15px 20px;
    margin: 0;
}
.register_modal .second_title{
    color:#000;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 0;
}  
.register_modal .modal-body{
    background: #f1f1f1;
    max-height: 500px;
    overflow: auto;
} 
.register_modal  .gridtable {
    max-height: 400px;
}
.register_modal .gridtable thead th,
.register_modal .gridtable tbody td {
    padding: 10px 15px;
}

.register_modal .gridtable thead { 
    z-index: 1;
}
.register_modal .gridtable thead th{
    color: var(--themeColor);
}
.register_modal .gridtable{
    margin: 0px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
}
.register_modal .gridtable tbody tr {
    background-color: #fff;
}
.register_modal  .custom-check .form-check-label{
    justify-content: center;
}
.register_modal .modal_btn {
    margin: 0;
}
.register_modal .modal_btn .btn{
    min-width: 120px;
    padding: 10px;
}
.register_modal .modal-md{
    max-width: 600px;
}
@media only screen and (max-width: 400px){
    .register_modal .modal_btn .btn {
        min-width: auto;
    }
}
/* Attention modal css end */

/* Shopping Cart Css Start */
.shoppingcart_box .span-bold{
    font-weight: 800;  
}
/* Shopping Cart Css End */


/* submit form css start */
.submit_form {
    display: flex;
    margin-top: 25px;
    justify-content: flex-end;
}

.submit_form .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 220px;
    margin-right: 15px;
}

.submit_form .btn:last-child {
    margin-right: 0px;
}

@media only screen and (max-width: 767px) {
    .submit_form {
        flex-flow: column;
    }

    .submit_form .btn {
        min-width: 100%;
        padding: 0.6rem;
        margin-right: 0px;
        margin-bottom: 12px;
    }
    .submit_form .btn:first-child{
        order: 2;
        margin: 0;
    }
}
/* submit form css end */

.theme_label {
    font-size: 16px;
    font-weight: 600;
    color: var(--themeLabelColor);
    line-height: normal;
}

.leaflet-control-attribution svg {
    display: none !important;
}